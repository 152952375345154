import config from '../config';
import axios from 'axios';
import { toast } from 'react-hot-toast';

let activeRequests = 0;
let toastId = null;

function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return { Authorization: 'Bearer ' + user };
    } else {
      return {};
    }
}
export default async function apiRequest(type,path,data = {}) {
    try{
        if(!type || !path) {
            throw new Error('type/path undefined')
        }

        activeRequests++;

        if (activeRequests === 1) {
          toastId = toast.loading('');
        }

        const headers = authHeader();

        let request = await axios({
            method: type,
            url: config.API_URL + path,
            data: data,
            headers: headers
          });
          
        toast.dismiss(toastId);

        return request;
    } catch(error){
        console.log('apiRequest error: ',type,config.API_URL + path,error)
        throw new Error('failed to apiRequest')
    } finally {
      activeRequests--;
      if (activeRequests === 0 && toastId) {
          toast.dismiss(toastId);
          toastId = null;
      }
    }
  }
