import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Protected from '../../Protected.js';
const Admin = lazy(() => import("pages/Dashboard/Admin"));
const DashboardAdmin = lazy(() => import("pages/Dashboard/DashboardAdmin/index"));
const RequestManagement = lazy(() => import("pages/Dashboard/RequestManagement"));
const WorkFlow = lazy(() => import("pages/Dashboard/WorkFlow/BranchList"))
const WorkFlowConfigration = lazy(() => import("pages/Dashboard/WorkFlow/BranchConfigration"))

const UserApproval = lazy(() => import("pages/Dashboard/UserApproval"))
const UserTransfare = lazy(() => import("pages/Dashboard/UserTransfare"))
const UserGroup = lazy(() => import("pages/Dashboard/UserGroup"))
const BookingManagement = lazy(() => import("pages/Dashboard/BookingManagement"));
const NewBook = lazy(() => import("pages/Dashboard/Book/NewBook"));
const NewBookEnd = lazy(() => import("pages/Dashboard/Book/NewBookEnd"));

const BookingRetake = lazy(() => import("pages/Dashboard/BookingRetake"));
const RetakeExam = lazy(() => import("pages/Dashboard/retakeExam"));
const BookRetake = lazy(() => import("pages/Dashboard/bookRetake"));
const BookRetakePayment = lazy(() => import("pages/Dashboard/bookRetake/BookRetakePayment"));
const BookingManagementRetake = lazy(() => import("pages/Dashboard/BookingManagementRetake"));

const Scheduling = lazy(() => import("pages/Dashboard/Scheduling"))
const AddExam = lazy(() => import("pages/Dashboard/AddExam"))
const EditExam = lazy(() => import("pages/Dashboard/EditExam"))
const ViewExam = lazy(() => import("pages/Dashboard/ViewExam"))
const AddTraning = lazy(() => import("pages/Dashboard/AddTraning"))
const EditTraning = lazy(() => import("pages/Dashboard/EditTraning"))
const ViewTraning = lazy(() => import("pages/Dashboard/ViewTraning"))
const Student = lazy(() => import("pages/Dashboard/Student"))
const StudentRegisterWizard = lazy(() => import("components/wizard/pages/Student"));
const StudentsProfile = lazy(() => import("components/profile/Wizard"));
const InstructorProfile = lazy(() => import("components/profile/Wizard"));

const UserManagement = lazy(() => import("pages/Dashboard/UserManagement"));
const EditUser = lazy(() => import("pages/Dashboard/EditUser"));
const AddUser = lazy(() => import("pages/Dashboard/AddUser"));
const UsersProfile = lazy(() => import("components/profile/Wizard"))
const BranchManagement = lazy(() => import("pages/Dashboard/BranchManagement"));
const AddBranch = lazy(() => import("pages/Dashboard/AddBranch"));
const EditBranch = lazy(() => import("pages/Dashboard/EditBranch"));
const BranchProfile = lazy(() => import("components/profile/Wizard"));
const AddClassToBranch = lazy(() => import("pages/Dashboard/AddClassToBranch"));

const CourseManagement = lazy(() => import("pages/Dashboard/CourseManagement"));
const AddNewCourse = lazy(() => import("pages/Dashboard/Course/addNewCourse"));
const EditNewCourse = lazy(() => import("pages/Dashboard/Course/editNewCourse"));
const CourseExam = lazy(() => import("pages/Dashboard/Course/addNewCourse/CourseExam"));
const CourseSessions = lazy(() => import("pages/Dashboard/Course/addNewCourse/CourseSessions"));

const AllPayment = lazy(() => import("pages/Dashboard/AllPayment"));
const AllPaymentStepOne = lazy(() => import("pages/Dashboard/AllPaymentStepOne"));
const FeesSetup = lazy(() => import("pages/Dashboard/FeesSetup"));
const EditFees = lazy(() => import("pages/Dashboard/EditFees"));
const AddFeesSetup = lazy(() => import("pages/Dashboard/AddFeesSetup"));
const RefundCorporate = lazy(() => import("pages/Dashboard/RefundCorporate"));
const RefundPayment = lazy(() => import("pages/Dashboard/RefundPayment"));

const AdvanceList = lazy(() => import("pages/Dashboard/AdvancePayments/AdvanceList"))
const AdvanceNew = lazy(() => import("pages/Dashboard/AdvancePayments/AdvanceNew"));
const BranchList = lazy(() => import("pages/Dashboard/LedgerSetup/BranchList"));
const BranchLedger = lazy(() => import("pages/Dashboard/LedgerSetup/BranchLedger"));
const AmanaInvoice = lazy(() => import("pages/Dashboard/amanaInvoice/AmanaInvoice"));
const AmanaInvoiceVerification = lazy(() => import("pages/Dashboard/amanaInvoice/AmanaInvoiceVerification"));
const AmanaBatch = lazy(() => import("pages/Dashboard/amanaInvoice/AmanaBatch"));

const DailyClosing = lazy(() => import("pages/Dashboard/DailyClosing"))
const TransactionList = lazy(() => import("pages/Dashboard/DailyClosing/TransactionList"))
const AddClosing = lazy(() => import("pages/Dashboard/DailyClosing/AddClosing"))
const UploadClosing = lazy(() => import("pages/Dashboard/DailyClosing/UploadClosing"))
const FinishClosing = lazy(() => import("pages/Dashboard/DailyClosing/FinishClosing"))

const Corporate = lazy(() => import("pages/Dashboard/Corporate"))
const CorporateProfile = lazy(() => import("components/profile/Wizard"));
const CorporateRegisterWizard = lazy(() => import("components/wizard/pages/Corporate"));
const Training = lazy(() => import("pages/Dashboard/Training"))
const TrainingRegisterWizard = lazy(() => import("components/wizard/pages/Training"));
const TraningProfile = lazy(() => import("components/profile/Wizard"));

const Medical = lazy(() => import("pages/Dashboard/Medical"))
const MedicalRegisterWizard = lazy(() => import("components/wizard/pages/Medical"));
const MedicalProfile = lazy(() => import("components/profile/Wizard"));

const HealthCheck = lazy(() => import("pages/Dashboard/HealthCheck"));

const ProductView = lazy(() => import("pages/Dashboard/ProductView"));
const AddProduct = lazy(() => import("pages/Dashboard/AddProduct"));
const EditProduct = lazy(() => import("pages/Dashboard/EditProduct"));

const Reports = lazy(() => import("pages/Dashboard/Report"))


const InstructorScheduling = lazy(() => import("pages/Dashboard/InstructorRelated/Scheduling"))
const InstructorCourses = lazy(() => import("pages/Dashboard/InstructorRelated/Booking/Booking"))
const InstructorBooking = lazy(() => import("pages/Dashboard/InstructorRelated/Courses/StudentDetails"))

// const Instructors = lazy(() => import("pages/Dashboard/Instructor"));
// const AddInstructor = lazy(() => import("pages/Dashboard/Instructor/add"));




const ExaminerScore = lazy(() => import("pages/ExamDashboard/Scores/Score"))
const ExaminerScheuling = lazy(() => import("pages/Dashboard/ExaminerRelatedAdmin/Scheduling"))
// const EditInstructor = lazy(() => import("pages/Dashboard/Instructor/edit"));
// const Examiners = lazy(() => import("pages/Dashboard/Examiner"));
// const AddExaminer = lazy(() => import("pages/Dashboard/Examiner/add"));
// const EditExaminer = lazy(() => import("pages/Dashboard/Examiner/edit"));


const AddExamInExaminer = lazy(() => import("pages/ExamDashboard/AddExam"))





const Score = lazy(() => import("pages/Dashboard/Score"));
const ChangePassword = lazy(() => import("pages/Login/ChangePassword"));

export function AdminRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Protected component={Admin} />}
      >

<Route path="dashboard" element={<Protected component={DashboardAdmin} />}></Route>
<Route path="request-management" element={<Protected component={RequestManagement} />}></Route>
<Route path='workflow' element={<Protected component={WorkFlow} type={'super admin'} />} ></Route>
<Route path='workflow/configration/:id' element={<Protected  component={WorkFlowConfigration} type={'super admin'} />} ></Route>


<Route path="user-approval" element={<Protected  component={UserApproval} />}></Route>
<Route path="student-transfer" element={<Protected  component={UserTransfare} />}></Route>
<Route path="user-group" element={<Protected component={UserGroup} type={['super admin', 'branch admin']} />}></Route>
<Route path="booking-management" element={<Protected permission={['read_9']} component={BookingManagement} />}></Route>
<Route path="new-book" element={<Protected permission={['create_9']} component={NewBook} />}></Route>
<Route path="new-book/end" element={<Protected permission={['create_9']} component={NewBookEnd} />}></Route>

<Route path='retake-exam' element={<Protected permission={['read_9']} component={RetakeExam} />} ></Route>
<Route path='retake-exam/book-retake' element={<Protected permission={['create_9']} component={BookRetake} />} ></Route>
<Route path='retake-exam/book-retake/payment' element={<Protected permission={['create_9']} component={BookRetakePayment} />} ></Route>
<Route path='booking-management/book-details/:id' element={<Protected permission={['read_9']} component={BookingManagementRetake} />} ></Route>

<Route path="Scheduling" element={<Protected permission={['read_8']} component={Scheduling} />}></Route>
<Route path="students" element={<Protected permission={['read_3']} component={Student} />}></Route>
<Route path="add-exam" element={<Protected permission={['create_8']} component={AddExam} />}></Route>
<Route path="add-training" element={<Protected permission={['create_8']} component={AddTraning} />} ></Route>
<Route path="edit-exam/:id" element={<Protected permission={['edit_8']} component={EditExam} />}></Route>
<Route path="view-exam/:id" element={<Protected permission={['create_8']} component={ViewExam} />}></Route>
<Route path="edit-training/:id" element={<Protected permission={['edit_8']} component={EditTraning} />} ></Route>
<Route path="view-training/:id" element={<Protected permission={['create_8']} component={ViewTraning} />} ></Route>
<Route path="students/add-new" element={<Protected permission={['create_3']} component={StudentRegisterWizard} />}></Route>
<Route path="students/edit/:id" element={<Protected permission={['edit_3']} component={StudentRegisterWizard} />}></Route>
<Route path="students/profile/:id" element={<Protected permission={['read_3']} component={StudentsProfile} />}></Route>
<Route path="user-management" element={<Protected permission={['read_1']} component={UserManagement} />}></Route>
<Route path="add-user" element={<Protected permission={['create_1']} component={AddUser} />}></Route>
<Route path="edit-user/:id" element={<Protected permission={['edit_1']} component={EditUser} />}></Route>

<Route path="users/profile/:id" element={<Protected permission={['read_1']} component={UsersProfile} />}></Route>

<Route path="branch-management" element={<Protected permission={['read_2']} component={BranchManagement} />}></Route>
<Route path="add-branch" element={<Protected permission={['create_2']} component={AddBranch} />}></Route>
<Route path="edit-branch/:id" element={<Protected permission={['edit_2']} component={EditBranch} />}></Route>
<Route path="branches/profile/:id" element={<Protected permission={['read_2']} component={BranchProfile} />}></Route>
<Route path="add-class-to-branch/:id" element={<Protected permission={['create_5']} component={AddClassToBranch} />}></Route>

<Route path="course-management" element={<Protected permission={['read_6']} component={CourseManagement} />}></Route>
<Route path='add-new-course' element={<Protected permission={['create_6']} component={AddNewCourse} />} />
<Route path='edit-course/:id' element={<Protected permission={['edit_6']} component={EditNewCourse} />} />
<Route path='add-new-course/:id' element={<Protected permission={['create_6']} component={AddNewCourse} />} />
<Route path='add-new-course/sessions/:id' element={<Protected permission={['create_6']} component={CourseSessions} />} />

<Route path="all-payments" element={<Protected permission={['read_10', 'read_17']} component={AllPayment} />}></Route>
<Route path="all-payments/:id" element={<Protected permission={['read_10', 'read_17']} component={AllPaymentStepOne} />}></Route>

<Route path="fees-setup" element={<Protected permission={['read_10']} component={FeesSetup} />}></Route>
<Route path="add-fees-setup" element={<Protected permission={['create_10']} component={AddFeesSetup} />}></Route>
<Route path="edit-fees/:id" element={<Protected permission={['edit_10']} component={EditFees} />}></Route>
<Route path="refund-corporate" element={<Protected permission={['create_10', 'create_17', 'read_10', 'read_17']} component={RefundCorporate} />}></Route>
<Route path="refund-payment" element={<Protected permission={['create_10', 'create_17', 'read_10', 'read_17']} component={RefundPayment} />}></Route>
{/* BranchLedger */}
<Route path='ledger-setup' element={<Protected permission={['read_10']} component={BranchList} />} ></Route>
<Route path='ledger-setup/:id' element={<Protected permission={['read_10']} component={BranchLedger} />} ></Route>

<Route path='advanced-payment' element={<Protected permission={['read_10']} component={AdvanceList} />} ></Route>
<Route path='advanced-payment/new' element={<Protected permission={['create_10']} component={AdvanceNew} />} ></Route>
<Route path='amana-invoice' element={<Protected permission={['read_10', 'read_18']} component={AmanaInvoice} />} ></Route>
<Route path='amana-invoice/:id' element={<Protected permission={['read_10', 'read_18']} component={AmanaBatch} />} ></Route>
<Route path='amana-invoice-verification' element={<Protected permission={['read_10', 'read_18']} component={AmanaInvoiceVerification} />} ></Route>

<Route path="daily-closing" element={<Protected permission={['read_10', 'read_17']} component={DailyClosing} />}></Route>
<Route path="daily-closing/:id" element={<Protected permission={['read_10', 'read_17']} component={TransactionList} />}></Route>
<Route path="daily-closing/add-closing" element={<Protected permission={['create_10', 'create_17']} component={AddClosing} />}></Route>
<Route path="daily-closing/add-closing/upload" element={<Protected permission={['create_10', 'create_17']} component={UploadClosing} />}></Route>
<Route path="daily-closing/add-closing/finish" element={<Protected permission={['create_10', 'create_17']} component={FinishClosing} />}></Route>

<Route path="corporate" element={<Protected permission={['read_4']} component={Corporate} />}></Route>
<Route path="corporate/profile" element={<Protected permission={['read_4']} component={CorporateProfile} />}></Route>
<Route path="corporate/add-new" element={<Protected permission={['create_4']} component={CorporateRegisterWizard} />}></Route>
<Route path="corporate/edit/:id" element={<Protected permission={['edit_4']} component={CorporateRegisterWizard} />}></Route>

<Route path="training-center" element={<Protected type={'super admin'} component={Training} />}></Route>
<Route path="training-center/add-new" element={<Protected type={'super admin'} component={TrainingRegisterWizard} />}></Route>
<Route path="training-center/edit/:id" element={<Protected type={'super admin'} component={TrainingRegisterWizard} />}></Route>
<Route path="training-center/profile/:id" element={<Protected type={'super admin'} component={TraningProfile} />}></Route>

<Route path="medical-center" element={<Protected type={'super admin'} component={Medical} />}></Route>
<Route path="medical-center/add-new" element={<Protected type={'super admin'} component={MedicalRegisterWizard} />}></Route>
<Route path="medical-center/edit/:id" element={<Protected type={'super admin'} component={MedicalRegisterWizard} />}></Route>
<Route path="training-center/profile/:id" element={<Protected type={'super admin'} component={TraningProfile} />}></Route>
<Route path="medical-center/profile/:id" element={<Protected type={'super admin'} component={MedicalProfile} />}></Route>


<Route path="health-check" element={<Protected permission={['read_20']} component={HealthCheck} />}></Route>


<Route path='product' element={<Protected permission={['read_16']} component={ProductView} />} ></Route>
<Route path='product-add' element={<Protected permission={['read_16', 'create_16']} component={AddProduct} />} ></Route>
<Route path='edit-product/:id' element={<Protected permission={['read_16', 'create_16']} component={EditProduct} />} ></Route>

<Route path='reports' element={<Protected permission={['read_16', 'create_16']} component={Reports} />} ></Route>
{/*
<Route path='reports/branch-revenue' element={<Protected permission={['read_16', 'create_16']} component={BranchRevenueReports} />} ></Route>
<Route path='reports/detailed-revenue' element={<Protected permission={['read_16', 'create_16']} component={DetailedRevenueReports} />} ></Route>
<Route path='reports/curent-course-revenue' element={<Protected permission={['read_16', 'create_16']} component={CurentCourseRevenueReports} />} ></Route>
<Route path='reports/current-traning-revenue' element={<Protected permission={['read_16', 'create_16']} component={CurentTrainingRevenueReports} />} ></Route>
<Route path='reports/statement-of-cancellation-course' element={<Protected permission={['read_16', 'create_16']} component={StatmentCoursegRevenueReports} />} ></Route>
<Route path='reports/statement-of-cancellation-traning' element={<Protected permission={['read_16', 'create_16']} component={StatmentTraningRevenueReports} />} ></Route> */}

<Route path='add-new-course/exam/:id' element={<Protected permission={['create_6']} component={CourseExam} />} />
<Route path="instructor/profile/:id" element={<Protected permission={['read_1']} component={InstructorProfile} />}></Route>

{/* <Route path="instructors" element={<Protected permission={['read_1']} component={Instructors} />}></Route>
<Route path="add-instructor" element={<Protected permission={['create_1']} component={AddInstructor} />}></Route>
<Route path="edit-instructor/:id" element={<Protected permission={['edit_1']} component={EditInstructor} />}></Route> */}

{/* <Route path="examiners" element={<Protected permission={['read_1']} component={Examiners} />}></Route>
<Route path="add-examiner" element={<Protected permission={['create_1']} component={AddExaminer} />}></Route>
<Route path="edit-examiner/:id" element={<Protected permission={['edit_1']} component={EditExaminer} />}></Route>
 */}

<Route path="add-exam/:id" element={<Protected permission={['read_16', 'create_16']} component={AddExamInExaminer} />} ></Route>

<Route path='instructor-scheduling' element={<Protected permission={['read_16', 'create_16']} component={InstructorScheduling} />} ></Route>
<Route path='instructor-courses' element={<Protected permission={['read_16', 'create_16']} component={InstructorCourses} />} ></Route>
<Route path='instructor-courses/:id' element={<Protected permission={['read_16', 'create_16']} component={InstructorBooking} />} ></Route>
<Route path='scores' element={<Protected permission={['read_19']} component={Score} />} ></Route>
<Route path='examiner-score' element={<Protected permission={['read_16', 'create_16']} component={ExaminerScore} />} ></Route>
<Route path='examiner-scheduling' element={<Protected permission={['read_16', 'create_16']} component={ExaminerScheuling} />} ></Route>

<Route path="change-password" element={<Protected component={ChangePassword} />} />

{/*





{/*




*/}



      </Route>

    </Routes>

  );
}
