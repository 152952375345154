import React from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import App from './App';
import './App.css';
import './utilities.css';
import './i18n/config';
import './index.css';
import './assets/css/tailwind.output.css';
// import 'swiper/css';
// import 'swiper/css/pagination';
import {SidebarProvider} from 'context/SidebarContext';
import DrawerContextProvider from 'context/DrawerContext';
import NewCourseContextProvider from 'context/NewCourseSteperContext';
import BookRetakeContextProvider from 'context/BookRetakeContext';
import BookingManagementContextProvider from 'context/BookingManagementContext';
import '@iconscout/unicons/css/line.css';
import './axiosSetup';

function Root() {

  console.log = console.warn = console.error = console.info = () => {};
  window.onerror = () => false;
  window.addEventListener('error', (e) => e.preventDefault());

  // use the useTranslation hook inside a function component
  const { i18n } = useTranslation();
  const lang = localStorage.getItem("lang") || i18n.language;

  const dir =  lang === 'en' ? 'ltr' : 'rtl'
  // console.log('index dir: ',dir)
  return (
    <div dir={dir}>
      {/* <Provider store={store}> */}
        <SidebarProvider>
          <DrawerContextProvider>
            <NewCourseContextProvider>
              <BookRetakeContextProvider>
                <BookingManagementContextProvider>
                  <App />
                </BookingManagementContextProvider>
              </BookRetakeContextProvider>
            </NewCourseContextProvider>
          </DrawerContextProvider>
        </SidebarProvider>
      {/* </Provider> */}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Root /> 
  // </React.StrictMode>
);
