import { useContext , createContext , useState } from 'react';
import { useTranslation } from 'react-i18next';

const BookRetakeContext = createContext();

export const useBookRetakeContext = () => useContext(BookRetakeContext) 


const BookRetakeContextProvider = ({ children }) => {
    const { t, i18n } = useTranslation();

    const steps = [t("Select Date"), t("Payment And Confirm")];
    const [currentStep, setCurrentStep] = useState(1);
    const [complete, setComplete] = useState(false);

    return (
        <BookRetakeContext.Provider
            value={{
                steps , 
                currentStep , setCurrentStep ,
                complete , setComplete
            }}
        >
            {children}
        </BookRetakeContext.Provider>
    )
}

export default BookRetakeContextProvider;