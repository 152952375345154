import axios from 'axios';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (process.env.NODE_ENV === 'production') {
      // Silently handle errors in production
    }
    return Promise.reject(new Error('Request failed'));

    // Log the error in development
    console.error('Axios Error: ', error);
    return Promise.reject(error);
  }
);
