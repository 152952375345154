import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Protected from "../../Protected.js";
import ChangePassword from "pages/Login/ChangePassword.js";

const InstructorAdmin = lazy(() => import("pages/InstructorDashboard/Admin"));
const InstructorDashboard = lazy(() =>
  import("pages/InstructorDashboard/Dashboard")
);
const SchedulingInstructor = lazy(() =>
  import("pages/InstructorDashboard/Scheduling")
);
const BookingInstructor = lazy(() =>
  import("pages/InstructorDashboard/Booking/Booking")
);
const InstructorBookingDetails = lazy(() =>
  import("pages/InstructorDashboard/Courses/StudentDetails")
);
const InstructorContact = lazy(() =>
  import("pages/InstructorDashboard/Contact/Contact")
);

const ViewExam = lazy(() => import("pages/Dashboard/ViewExam"));
const ViewTraning = lazy(() => import("pages/Dashboard/ViewTraning"));

export function InstructorRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Protected type={"Instructor"} component={InstructorAdmin} />}
      >
        <Route
          path="dashboard"
          element={
            <Protected type={"Instructor"} component={InstructorDashboard} />
          }
        />
        <Route
          path="scheduling"
          element={
            <Protected type={"Instructor"} component={SchedulingInstructor} />
          }
        />
        <Route
          path="courses"
          element={
            <Protected type={"Instructor"} component={BookingInstructor} />
          }
        />
        <Route
          path="course/:id"
          element={
            <Protected
              type={"Instructor"}
              component={InstructorBookingDetails}
            />
          }
        />
        <Route
          path="contacts"
          element={
            <Protected type={"Instructor"} component={InstructorContact} />
          }
        />

        <Route
          path="change-password"
          element={<Protected type={"Instructor"} component={ChangePassword} />}
        />

        <Route
          path="view-exam/:id"
          element={<Protected type={"Instructor"} component={ViewExam} />}
        ></Route>
        <Route
          path="View-training/:id"
          element={<Protected type={"Instructor"} component={ViewTraning} />}
        ></Route>
      </Route>
    </Routes>
  );
}
