import config from "../config";
import axios from "axios";
// import {  useSelector } from "react-redux";
import Cookies from "js-cookie";



const hasAccess = (resourseId, action) => {
  if (!resourseId || !action) {
    return false;
  }

  const adminInfo = Cookies.get("privileges");// localStorage.getItem("privileges");
  // console.log('adminInfo; ', adminInfo)
  const resources = adminInfo ? JSON.parse(adminInfo) : [];
  const resource = resources.find((res) => res.id === resourseId);
  if (resource) {
    return resource[action] || false;
  }
  return false;
};

const getResourcePermissionsById = (resourseId) => {
  try {
    if (!resourseId) {
      return false;
    }
    const adminInfo = Cookies.get("privileges");// localStorage.getItem("privileges");

    // const adminInfo = localStorage.getItem("privileges");
    //console.log('adminInfo; ', adminInfo)
    const resources = adminInfo ? JSON.parse(adminInfo) : [];
    const resource = resources.find((res) => res.id === resourseId);
    // console.log('resource; ', resource)

    return resource;
  } catch (error) {
    console.log("checkPermission error: ", error);
    return false;
  }
};

const getUserType = () => {

  const userType = Cookies.get("userType");
  return userType;
};

// const getCurrentUserBranch = () => {

//   const userType = Cookies.get("userType"); //localStorage.getItem("userType");
//   return userType;
// };

const permissionService = {
  getResourcePermissionsById,
  hasAccess,
  getUserType,
};

export default permissionService;




// 1		المستخدمين	Users	# Done
// 2		الفرع	Branch # Done
// 3		متدربين	Student	 # Done
// 4		مؤسسات	Corporation	 # Done
// 5		القاعات	Classroom	 # Done
// 6		التدريب	Course	# Done
// 7		الإختبارات	Exam	
// 8		الجداول	Schedules	# Done
// 9		الحجز	Booking	 # Done
// 10	  المالية	Finance	# DONE
// 11		تقارير المستخدمين	Users reports	
// 12	  التقارير المالية	Finance reports	
// 13		تقارير الشهادات	Certificates reports	
// 14		تقارير التدريب	Training reports	
// 15		تقارير الإخبارات	Exam reports	
// 16		المنتجات	Products # DONE
// 17		Internal Finance	Internal Finance	
// 18		External Finance	External Finance	
// 19		Score	Score	
// 20		Health Check Result	Health Check Result	# Done