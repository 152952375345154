import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import logo from '../../assets/img/mh-logo.png';
import config from "../../config";
import authImg from "../../assets/images/auth.png";
import eyeInvisible from "../../assets/svgs/eyeInvisible.svg";
import eyeVisible from "../../assets/svgs/eyeVisible.svg";
import studentSVG from "../../assets/svgs/studentReg.svg";
import corporateSVG from "../../assets/svgs/corporateReg.svg";
import medicalSVG from "../../assets/svgs/medicalCenterReg.svg";
import trainingSVG from "../../assets/svgs/trainingCenterReg.svg";
import { useTranslation } from "react-i18next";
import apiRequest from "services/axois";
import Cookies from "js-cookie";
import AlertServices from "services/alertServices";
import OtpInput from "react-otp-input";

const cookieTimeOut = 0.5; // half day
const cookieOptions = {
  expires: cookieTimeOut,
  sameSite: "None",
  secure: true,
  // sameSite: "Lax",
  // secure: false,

};
function Login() {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState("en");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginErrpor] = useState(true);
  const [loading, setLoading] = useState(false);
  const [forget, setForget] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [forgetError, setForgetError] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [allData, setAllData] = useState({});

  const [showErrors, setShowErrors] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname.length < 2) {
    location.pathname = "/login";
  }


  async function login() {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("privileges");
    localStorage.removeItem("type");
    localStorage.removeItem("studentId");

    if (!email || email === "" || !password || password === "") {
      AlertServices.AlertMessage(t("Please fill out all fields."), "error");

      setLoading(false);
      return;
    }
    // navigate('/admin/dashboard');
    // test@test.com
    // password123

    //   localStorage.setItem("user", 'sdsdcskljdnfadlnkcadca')
    //   navigate('/admin/dashboard');
    //   return
    await apiRequest("post", "/auth/login", {
      email: email,
      password: password,
    })
      .then((res) => {
        // console.log('res? ',res)
        // // console.log('res.status ',res.status)
        if (res.data.status === 401) {
          setLoading(false);
          setShowErrors(true);
        }
        // if (res.data.accessToken) {
        if (res.data.access_token) {
          // localStorage.setItem("user", JSON.stringify(res.data.accessToken));
          localStorage.setItem("user", JSON.stringify(res.data.access_token));
          // localStorage.setItem("userId", res.data?.id);
          localStorage.setItem("userId", JSON.stringify(res.data?.userId));
          localStorage.setItem("type", res.data?.type);
          Cookies.set("userType", res.data?.type, cookieOptions);
          Cookies.set(
            "privileges",
            JSON.stringify(res?.data?.privileges),
            cookieOptions
          );

          // localStorage.setItem("privileges", JSON.stringify(res.data?.privileges));
          // navigate('/admin/dashboard');
          setTimeout(() => {
            //'Amanah','Teller','Instructor','super admin','manager','student','corporate','branch admin'
            if (res.data?.type === "student") {
              localStorage.setItem("studentId", res.data?.studentId);
              navigate("/student/dashboard");
              return;
            }
            if (res.data?.type === "corporate") {
              localStorage.setItem("corporateId", res.data?.corporateId);
              navigate("/corporate/dashboard");
              return;
            }
            if (res.data?.type === "Instructor") {
              navigate("/instructor/dashboard");
              return;
            }
            if (res.data?.type === "Examiner") {
              navigate("/examiner/dashboard");
              return;
            } else {
              navigate("/admin/dashboard");
            }
          }, 1000);
        }
      })
      .catch((error) => {
        // console.log('error? ', error)
        setLoading(false);
        setShowErrors(true);
      });

    // if (email === 'test@test.com') {
    //   navigate('/admin/dashboard');
    // } else {
    // }
  }

  const [otpNumber, setOtpNumber] = useState("");
  const [idFromOtp, setIdFromOtp] = useState("");
  const [maskedPhone, setMaskedPhone] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  
  const handleChange = (otpNumber) => {
    // if(isNaN(Number(otpNumber))) return
    setOtpNumber(otpNumber);
  };

  let id;

  async function createOTP() {
    try {
      setLoading(true);

      let data;
      data = await apiRequest("post", "/users/reset-otp", {
        email: forgetEmail,
      });
      if (data.data.statusCode === 404) {
        setLoading(false);
        AlertServices.AlertMessage(t("Email Not Registered"), "error");
        return;
      }
      const phone = data.data?.phone;
      const lastTwo = phone?.slice(-2);
      const asterisks = "*".repeat(phone ? phone?.length - 2 : 0);
      const maskedPhone = asterisks + lastTwo;

      setIdFromOtp(data.data.id);
      setShowOTP(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      AlertServices.AlertMessage(
        t("Please try again"),
        "error"
      );
    }
  }

  async function verifyOTP() {
    let data;
    data = await apiRequest("post", "/users/verify-reset-otp", {
      id: idFromOtp,
      otp: otpNumber,
    })
      .then(async (res) => {
        console.log('res : ', res);
        if (res) {
          if (res.data.statusCode === 404) {
            AlertServices.AlertMessage(t("Email Not Registered"), "error");
            return;
          }
          // setShowNewPassword(true);
          setShowOTP(false);
          setForget(false);
          setOtpNumber("");
          setForgetEmail('')
          AlertServices.AlertMessage(t("Password has been sent to you"), "success");
        }
      })
      .catch((error) => {
        console.log('verifyOTP error: ', error);
        AlertServices.AlertMessage(t("OTP is not valid"), "error");
      });
  }

  const [checkPassword, setCheckPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passowrdErrorMessage, setPasswordErrorMessage] = useState("");

  // const handleChangeWithName = (value, name) => {
  //   setAllData({ ...allData, [name]: value });
  // };

  // const validatePassword = (password) => {
  //   const errors = [];

  //   // Length check
  //   // if (password.length < 8 || password.length > 20) {
  //   //   errors.push("Password must be between 8 and 20 characters.");
  //   // }

  //   if (password.length < 8) {
  //     errors.push("Password must be more than 8");
  //   }

  //   // Complexity checks
  //   if (!/[A-Z]/.test(password)) {
  //     errors.push("Password must contain at least one uppercase letter.");
  //   }
  //   if (!/[a-z]/.test(password)) {
  //     errors.push("Password must contain at least one lowercase letter.");
  //   }
  //   if (!/[0-9]/.test(password)) {
  //     errors.push("Password must contain at least one number.");
  //   }
  //   if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
  //     errors.push("Password must contain at least one special character.");
  //   }
  //   if (errors.length > 0) {
  //     setPasswordErrorMessage(errors[0]);
  //     setCheckPassword(true);
  //     return;
  //   }
  //   setAllData({ ...allData, newPassword: password });
  //   setCheckPassword(false);
  // };
  // async function updatePassword(user) {
  //   try {
  //     let password = allData.newPassword;
  //     let rePassword = allData.confirmPassword;
  //     //// console.log('pass: ',password,rePassword);
  //     if (password !== rePassword) {
  //       setCheckPassword(true);
  //       return;
  //     }
  //     let userId = 
  //     setCheckPassword(false);
  //     let resetData = {
  //       userId: user.userId,
  //       password,
  //       rePassword,
  //     };
  //     let res = await apiRequest("post", "/users/update-password", resetData);
  //     if (res.data && (res.status === 200 || res.status === 201)) {
  //       setPasswordChanged(true);
  //       AlertServices.AlertMessage(
  //         t("the password have been changed successfully!"),
  //         "success"
  //       );
  //       return;
  //     }
  //     // failed
  //   } catch (error) {
  //     // error
  //   }
  // }

  return (
    <>
      {showOTP && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="mx-6 my-6 mx-auto max-w-4xl rounded lg:w-1/3 ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <h1 className="text-center text-3xl font-bold mt-12">
                  OTP Verification
                </h1>
                <p className="text-center mt-4 text-[#718096]">
                  Please enter one time OTP to verify your account
                </p>
                <p className="text-center mt-4 font-bold">
                  A code has been sent to mobile number: {maskedPhone}
                </p>
                <div
                  id="otp"
                  className="flex flex-row justify-center text-center px-2 mt-5"
                >
                  <OtpInput
                    value={otpNumber}
                    onChange={handleChange}
                    numInputs={4}
                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    // isInputNum={true}
                    inputType={"number"}
                    renderInput={(props) => (
                      <input
                        min={0}
                        type={"number"}
                        {...props}
                        className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      />
                    )}
                    placeholder="----"
                    inputStyle={{
                      border: "1px solid #E8E8E8",
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "400",
                      // caretColor: 'black',
                    }}
                  />
                </div>
                {/* <p className="text-center mt-4 font-bold">
             Don't Get The Code {"  "}
             <span className="underline text-[#046F6D] cursor-pointer decoration-[#046F6D]">
               Resend It
             </span>
           </p> */}
                <div className="flex items-center rounded-b m-12">
                  <button
                    className="text-white bg-[#046F6D] w-full h-12 rounded-md"
                    type="button"
                    onClick={() => {
                      verifyOTP();
                    }}
                  >
                    {t("Confirm")}
                  </button>
                  <button
                    className="text-white bg-red-600 w-full h-12 rounded-md"
                    type="button"
                    onClick={() => {
                      setShowOTP(false);
                      setForget(false);
                      setOtpNumber("");
                      setForgetEmail('');
                    }}
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="fixed top-0 left-0 w-full z-[99] sm:bg-[#fff] bg-primary">
        <div className="flex items-center justify-between w-[97%] mx-auto py-4 flex-row">
          <img className="w-80" src="/assets/images/mh-logo.png" alt="logo" />
        </div>
      </div>
      <div className="w-full pb-20 h-full mt-20">
        <div className="lg:w-[80%] w-[95%] h-full mx-auto flex justify-center gap-8 pt-20">
          <div className="flex-1 md:block hidden">
            <img src={authImg} alt="Auth" className="w-[80%]" />
          </div>
          <div className="flex-1">
            <div
              className={`flex items-center gap-12
                  ${locale === "en" ? "justify-start" : "justify-end"}
                  `}
            >
              {["login", "register", "products"].map((item, i) =>
                item !== "products" ? (
                  <div
                    onClick={() => {
                      navigate(`/${item}`);
                    }}
                    className={`font-semibold text-xl cursor-pointer
            ${
              location.pathname.includes(item)
                ? 'text-primary relative after:content-[""] after:absolute after:-bottom-3 after:left-0 after:w-full after:h-[5px] after:bg-[#046f6d]'
                : ""
            } `}
                    key={i}
                  >
                    {item === "login" ? t("Login") : t("Register")}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      navigate(`/products`);
                    }}
                    className={`font-semibold text-xl cursor-pointer`}
                    key={i}
                  >
                    {t("Products")}
                  </div>
                )
              )}
            </div>
            {location.pathname.includes("login") ? (
              <div className="mt-12">
                {forget ? (
                  <form className="flex flex-col gap-4">
                    {/* {showNewPassword ? (<>
                      <div className="">
                  <div className="">
                    <div className="div-dropdown-wizard w-full">
                      <input
                        className="w-full p-1"
                        type="password"
                        placeholder={t("New Password")}
                        onChange={
                          (e) => validatePassword(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="div-dropdown-wizard">
                      <input
                        className="w-full p-1"
                        type="password"
                        placeholder={t("Confirm Password")}
                        onChange={(e) =>
                          handleChangeWithName(
                            e.target.value,
                            "confirmPassword"
                          )
                        }
                      />
                    </div>
                    <>
                      {checkPassword === true ? (
                        <span className="text-red-400">
                          {passowrdErrorMessage.length > 2
                            ? passowrdErrorMessage
                            : t("Password and Re-Password does not match.")}
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>

                  {passwordChanged == true ? (
                    <span className="text-green-600">
                      {t("Password has been successfuly Changed")}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="flex justify-between">
                  <button
                    className="w-full sm:w-auto  border border-gray-200 px-10 rounded-lg py-3 text-black"
                    layout="outline"
                    onClick={() => setForget(false)}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    onClick={() => {
                      updatePassword();
                    }}
                    className="w-full sm:w-auto  bg-[#046F6D] px-10 rounded-lg py-3 text-white"
                  >
                    {t("Reset Password")}
                  </button>
                </div>
                    </>)
                    
                    :
                    <> */}
                    <div className="flex flex-col gap-2">
                    <label
                      className={`${
                        i18n.language === "en" || locale === "en" ? "" : ""
                      }`}
                    >
                      {t("Enter Registered Email")}
                    </label>
                    <input
                      type="text"
                      placeholder="mail@example.com"
                      className={`py-2 px-3 rounded-md text-dark focus:border-primary border outline-none text-left ${
                        locale === "en" ? "text-left" : "text-right"
                      }`}
                      value={forgetEmail}
                      onChange={(event) => setForgetEmail(event.target.value)}
                    />
                  </div>

                  {showErrors && forgetError ? (
                    <span className="text-red-400">{t("Invalid email")}</span>
                  ) : (
                    <></>
                  )}

                  <div className="mt-2">
                    <div
                      onClick={() => setForget(false)}
                      className="my-4 w-max font-semibold text-primary hover:cursor-pointer hover:underline hover:text-primaryHover"
                    >
                      {t("Login")}
                    </div>
                    {loading ? (
                      <div className="flex items-center justify-center">
                        <span className="font-bold mx-3"> Loading ... </span>
                        <div
                          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <button
                        disabled={loading}
                        className="py-2 px-6 bg-[#046f6d] text-[#fff] rounded-tl-[22px] rounded-br-[22px] rounded-bl-[7px] rounded-tr-[7px] hover:cursor-pointer hover:bg-primaryHover w-full"
                        onClick={(event) => {
                          event.preventDefault();
                          createOTP();
                        }}
                      >
                        {t("Submit")}
                      </button>
                    )}
                  </div>
                  {/* </>
                    } */}
                   
                  </form>
                ) : (
                  <form className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <label
                        className={`${
                          i18n.language === "en" || locale === "en" ? "" : ""
                        }`}
                      >
                        {t("Email / ID")}
                      </label>
                      <input
                        type="text"
                        placeholder="mail@example.com"
                        className={`py-2 px-3 rounded-md text-dark focus:border-primary border outline-none text-left
                  ${locale === "en" ? "text-left" : "text-right"}
                  `}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                    <div className="flex flex-col gap-2 relative">
                      <label
                        className={`${
                          i18n.language === "en" || locale === "en" ? "" : ""
                        }`}
                      >
                        {t("Password")}
                      </label>
                      <input
                        type={`${showPassword ? "text" : "password"}`}
                        placeholder="**********"
                        className={`py-2 px-3 rounded-md text-dark focus:border-primary border outline-none text-left
                  ${locale === "en" ? "text-left" : "text-right"}
                  `}
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                      />
                      <div
                        className={`absolute ${
                          locale === "en" ? "right-2" : "left-2"
                        } top-[55%] cursor-pointer w-fit`}
                      >
                        {showPassword ? (
                          <img
                            src={eyeInvisible}
                            alt="Eye"
                            className="w-[24px] "
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <img
                            src={eyeVisible}
                            alt="Eye"
                            className="w-[24px]"
                            onClick={() => setShowPassword(true)}
                          />
                        )}
                      </div>
                    </div>
                    {showErrors && loginError ? (
                      <span className="text-red-400">
                        {t("Invalid email or password")}
                      </span>
                    ) : (
                      <></>
                    )}
                    {/* <div className="flex items-cente justify-between mt-4">
                    <div className="flex items-center gap-2 text-sm">
                      <input type="checkbox" id="rememberMe" />
                      <label htmlFor="rememberMe">{t('Remember Me')}</label>
                    </div>
                    <div className="text-grayText text-sm cursor-pointer">
                      {t('Forget Password')}
                    </div>
                  </div> */}
                    <div className="mt-2">
                      <div
                        onClick={() => setForget(true)}
                        className="my-4 w-max font-semibold text-primary hover:cursor-pointer hover:underline hover:text-primaryHover"
                      >
                        {" "}
                        {t('Forget Password ?')}
                      </div>
                      {loading ? (
                        <div className="flex items-center justify-center">
                          <span className="font-bold mx-3"> Loading ... </span>
                          <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          ></div>
                        </div>
                      ) : (
                        <button
                          disabled={loading}
                          className="py-2 px-6 bg-[#046f6d] text-[#fff] rounded-tl-[22px] rounded-br-[22px] rounded-bl-[7px] rounded-tr-[7px] hover:cursor-pointer hover:bg-primaryHover w-full"
                          onClick={(event) => {
                            event.preventDefault();
                            setLoading(true);
                            login();
                          }}
                        >
                          {t("Login")}
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </div>
            ) : (
              <div
                className={`flex flex-col gap-4 w-full mt-12
    ${locale === "en" ? "justify-start" : "justify-end"}
    `}
              >
                <p
                  className={`text-grayText w-full
        ${locale === "en" ? "" : ""}
        `}
                >
                  {t(
                    "Select one of these option to determine how will you register"
                  )}
                </p>
                <Link to="/student-register">
                  <div
                    className={`flex items-center gap-4 mt-4
        ${locale === "en" ? "flex-row" : "flex-row-reverse"}
        `}
                    onClick={() => {}}
                  >
                    <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                      <img src={studentSVG} alt="SVG" />
                    </div>
                    <p className="font-semibold">{t("I Am Student")}</p>
                  </div>
                </Link>
                <Link to="/corporate-register">
                  <div
                    className={`flex items-center gap-4 mt-4
        ${locale === "en" ? "flex-row" : "flex-row-reverse"}
        `}
                  >
                    <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                      <img src={corporateSVG} alt="SVG" />
                    </div>
                    <p className="font-semibold">{t("Corporate")}</p>
                  </div>
                </Link>
                <Link to="/training-register">
                  <div
                    className={`flex items-center gap-4 mt-4
        ${locale === "en" ? "flex-row" : "flex-row-reverse"}
        `}
                  >
                    <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                      <img src={trainingSVG} alt="SVG" />
                    </div>
                    <p className="font-semibold">{t("Training Center")}</p>
                  </div>
                </Link>
                <Link to="/medical-register">
                  <div
                    className={`flex items-center gap-4 mt-4
        ${locale === "en" ? "flex-row" : "flex-row-reverse"}
        `}
                  >
                    <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                      <img src={medicalSVG} alt="SVG" />
                    </div>
                    <p className="font-semibold">{t("Medical Center")}</p>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
