import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {toast} from 'react-hot-toast'
import apiRequest from "services/axois";
import AlertServices from "services/alertServices";
import permissionServices from 'services/checkPermission'
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [passowrdError, setPasswordError] = useState(true);
  const [passowrdErrorMessage, setPasswordErrorMessage] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedToken = permissionServices.getUserType();
    if (!storedToken) {
      navigate("/");
    } 
  }, [navigate]);

  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push("Password must be more than 8");
    }

    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    if (errors.length > 0) {
      setPasswordErrorMessage(errors[0]);
      setPasswordError(true);
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const oldPassword = e.target.oldPassword.value;
    const newPassword = e.target.newPassword.value;
    const confirmNewPassword = e.target.confirmNewPassword.value;

    if (newPassword !== confirmNewPassword) {
      toast.error("New passwords do not match!");
      return;
    }

    if(validatePassword(newPassword)){
      return;
    };

    try {
      const response = await apiRequest("post", "/auth/reset-password", {oldPassword, newPassword});
       if(response.data.statusCode === 200){
           AlertServices.AlertMessage(t("Password changed successfully!"), 'success')
       } else if(response.data.statusCode === 401){
          AlertServices.AlertMessage(t("Password is incorrect"), 'error')
       } else {
          AlertServices.AlertMessage(t("Please try again"), 'error')
       }
    } catch (error) {
      toast.error("Error");
      return false;
    }

  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
        <h2 className="text-xl font-bold text-center mb-6">Change Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700">
              Old Password
            </label>
            <input
              type="password"
              id="oldPassword"
              name="oldPassword"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-700">
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          {passowrdError ? (
                    <span className="text-red-400">
                      {passowrdErrorMessage}{" "}
                    </span>
                  ) : (
                    <></>
          )}
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md shadow hover:bg-primaryHover transition duration-150"
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
